import React, { useEffect, useRef, useState } from 'react'
// import { StaticImage } from 'gatsby-plugin-image'
import Hero from '../components/Blocks/Hero/Hero'
import PageNav from '../components/Blocks/PageNav/PageNav'
import Map from '../components/Map'

import { Layout } from '../layouts'
import Seo from '../components/seo'
import { INTRO } from '../content'
import SiteTitle from '../components/Blocks/SiteTitle/SiteTitle'

const IndexPage = () => {
  const scrollRef = useRef(null)
  const [intersecting, setIntersecting] = useState(false)

  useEffect(() => {
    if (scrollRef.current) {
      const options = {
        rootMargin: '0px',
      }

      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIntersecting(true)
          }
        })
      }, options)

      observer.observe(scrollRef.current)
    }
  }, [])
  return (
    <Layout>
      <Seo title="Emilie Pfister - Psychologue à Dieulouard" description={INTRO[0]} />
      <SiteTitle />
      <Hero />
      {/* <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={['AUTO', 'WEBP', 'AVIF']}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem`, zIndex: 0 }}
    /> */}

      <PageNav />

      <div ref={scrollRef}>{intersecting ? <Map /> : null}</div>
    </Layout>
  )
}

export default IndexPage
