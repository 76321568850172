import React from 'react'
import { Link } from 'gatsby'
import { CONTENT } from '../../../content'
import * as styles from './PageNav.module.scss'
import Psychology from '../../Icons/Psychology'
import Location from '../../Icons/Location'
import Payments from '../../Icons/Payments'
import LightBulb from '../../Icons/LightBulb'

const PageNav = () => {
  function setIcon(icon) {
    switch (icon) {
      case 'Psychology':
        return <Psychology />
      case 'Location':
        return <Location />
      case 'Payments':
        return <Payments />
      case 'LightBulb':
        return <LightBulb />
    }
  }

  return (
    <section className={styles.page_nav} id="page-navigation">
      <div className="wrapper">
        <header>
          <hgroup>
            <h2 className={styles.title}>{CONTENT.PAGE_NAV.title}</h2>
            <h3 className={styles.subtitle}>{CONTENT.PAGE_NAV.subtitle}</h3>
          </hgroup>
        </header>

        <nav>
          <ul className={styles.ul}>
            {CONTENT.PAGE_NAV.items.map(
              ({ title, content, icon, to }, index) => (
                <li key={index}>
                  <Link
                    to={to}
                    className={styles.item_link}
                    title={`Cliquez pour consulter la section ${title}`}
                  >
                    <span className={styles.item_icon}>{setIcon(icon)}</span>
                    <span>
                      <span className={styles.item_title}>{title}</span>
                      {content.map((c, i) => (
                        <span key={i} className={styles.item_content}>
                          {c.title}
                        </span>
                      ))}
                    </span>
                  </Link>
                </li>
              ),
            )}
          </ul>
        </nav>
      </div>
    </section>
  )
}

export default PageNav
