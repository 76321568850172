import React from 'react'
import * as styles from './Hero.module.scss'
import * as linkStyles from '../../UI/Link/Link.module.scss'
import FlowerVector from '../../illustrations/flowers'
import { INTRO } from '../../../content'

const Hero = ({ children }) => (
  <section className={styles.hero} id="presentation">
    <div className={`wrapper ${styles.hero_wrapper}`}>
      <FlowerVector className={styles.flowers} />

      <div className={styles.block}>
        <section className={styles.content}>
          {children ||
            INTRO.map((content, index) => (
              <p key={index} dangerouslySetInnerHTML={{ __html: content }} />
            ))}
        </section>
      </div>
    </div>
  </section>
)

export default Hero
